import { FC, memo, useEffect, useState } from 'react';
import { Layout } from 'src/components/Layout/Layout';
import { useStyle } from 'src/utils/theme/useStyle';
import { cartRules } from './сart.style';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import EmptyCart from 'src/components/EmptyCart/EmptyCart';
import { CartItem } from 'src/components/CartItem/CartItem';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { cartState, setPromoCode } from '../../redux/slices/cartSlice';
import { useTheme } from 'src/utils/theme/useTheme';
import { useModalState } from 'src/hooks/useModalState';
import ModalPromocode from 'src/components/ModalPromocode/ModalPromocode';
import useLoader from 'src/hooks/useLoader';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import useCurrency from 'src/hooks/useCurrency';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { telegramStarsString } from 'src/utils/constants';
import { RoutePath } from 'src/providers/AppRouter/routeConfig';
import { cartGetDetail } from '../../redux/api/cart/cartGetDetail';
import { Price } from '../../components/Price/Price';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { togglesState } from '../../redux/slices/configSlice';

export const Cart: FC = memo(function Cart() {
  const { cartEnabled } = useAppSelector(togglesState);
  const { css } = useStyle(cartRules);
  const { theme } = useTheme();
  const currency = useCurrency();
  const isTelegramCurrency = currency === telegramStarsString
  const { productsDetail, minPrice, total, promoCode, networkStatusDetail } = useAppSelector(cartState);
  const [totalState, setTotalState] = useState({
    initTotal: 0,
    totalPrice: 0,
    totalDiscount: 0,
  });

  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const BackButton = useBackButton();

  const [renderPromoModal, activePromoModal, openModalPromo, closeModalPromo] = useModalState();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (!cartEnabled) {
      showLoader();
      navigate(-1);
    }
  }, [cartEnabled, showLoader, navigate]);

  const clickOrderButton = () => {
    navigate(RoutePath.makeOrder);
  };

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    dispatch(cartGetDetail())
  }, [dispatch]);

  useEffect(() => {
    if (networkStatusDetail === NetworkStatus.Loading) {
      showLoader();
    }
    if (networkStatusDetail === NetworkStatus.Done || networkStatusDetail === NetworkStatus.Failed) {
      hideLoader();
    }
  }, [hideLoader, showLoader, networkStatusDetail]);

  useEffect(() => {
    setTotalState({
      totalDiscount: total.discountEnabled ? Number(total.oldAmount) - Number(total.amount) : 0,
      initTotal: Number(total.oldAmount),
      totalPrice: Number(total.amount)
    })
  }, [total])

  return (
    <Layout
      mainFlexColumn={true}
      footer={
        productsDetail.length ? undefined : (
          <Footer
            propsStyles={{
              backgroundColor: productsDetail.length === 0 ? theme.background : theme.colorWhite,
            }}
          >
            <Button href={'/catalog'} text={t('cart.catalog')} propsStyles={{ width: '100%', height: 62 }} />
          </Footer>
        )
      }
    >
      {productsDetail.length === 0 ? (
        <div className={css.cart_wrapper}>
          <EmptyCart />
        </div>
      ) : (
        <div className={css.cart_wrapper}>
          <div className={css.cards_wrapper}>
            {productsDetail.map(el => {
              return <CartItem extend={css.card} key={el.productUuid} data={el} />;
            })}
          </div>

          <div className={css.cart_info}>
            <Container>
              <div className={css.summary_row}>
                <div onClick={() => openModalPromo()} className={css.summary_row_inner}>
                  {promoCode?.name ? (
                    <div>
                      <p className={css.promocodeName}>{promoCode.name}</p>
                      <p className={css.promocodeVal}>{`${promoCode.discount * 100} %`}</p>
                    </div>
                  ) : (
                    <p>{t('cart.promoCode')}</p>
                  )}

                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.909 10.7143L15.9795 10.7143L11.1504 15.5434C10.8096 15.8842 10.8096 16.4524 11.1504 16.7932C11.4913 17.1341 12.0594 17.1341 12.4003 16.7932L18.7443 10.4492C19.0852 10.1083 19.0852 9.54021 18.7443 9.19934L12.4003 2.85531C12.2299 2.68487 12.0026 2.59018 11.7754 2.59018C11.5481 2.59018 11.3209 2.68487 11.1504 2.8553C10.8096 3.19618 10.8096 3.7643 11.1504 4.10518L15.9795 8.93421L1.89006 8.93421C1.39768 8.93421 1 9.3319 1 9.82427C1.01894 10.3166 1.41662 10.7143 1.909 10.7143Z"
                      fill="#1C1916"
                    />
                  </svg>
                </div>
              </div>
              <div className={css.summary_row}>
                <div className={css.summary_row_inner}>
                  <p>{t('cart.orderTotal')}</p>
                  <Price amount={totalState.initTotal} hasSymbol={false} fontWeight={700} fontSize={14} lineHeight={'18px'} />
                </div>
                {total.discountEnabled && totalState.totalDiscount > 0 && (
                  <div className={css.summary_row_inner}>
                    <p>{t('cart.discount')}</p>
                    <Price
                      amount={totalState.totalDiscount}
                      hasSymbol={true}
                      fontWeight={700}
                      fontSize={14}
                      lineHeight={'18px'}
                      isPositive={false}
                      color={theme.mainColor}
                      extend={css.total_disc}
                    />
                  </div>
                )}

                {promoCode?.name && (
                  <div className={css.summary_row_inner}>
                    <p>{t('cart.discountWithPromoCode')}</p>
                    <p className={`${css.total_disc} ${css.total_amount}`}>
                      - {totalState.initTotal * promoCode.discount} {isTelegramCurrency? <img style={{width: 20}} src={`/images/currencies/${currency}.svg`} alt='' /> : currency }{' '}
                    </p>
                  </div>
                )}
              </div>
              <div className={css.to_pay}>
                <p>{t('cart.goToPayment')}</p>
                <Price amount={totalState.totalPrice} hasSymbol={false} fontWeight={700} fontSize={20} lineHeight={'22px'} />
              </div>
              <Button
                disabled={totalState.totalPrice < minPrice}
                text=""
                propsStyles={{ width: '100%', height: 62 }}
                onClick={clickOrderButton}
              >
                <div>
                  <p style={{ fontSize: '16px', lineHeight: '18px', fontWeight: '700' }}>
                    {t('cart.checkout')}
                  </p>
                  {totalState.totalPrice < minPrice && (
                    <p style={{ fontSize: '12px', lineHeight: '14px', fontWeight: '400' }}>
                      {t('cart.minimumOrderFrom', minPrice)} {isTelegramCurrency? <img style={{width: 20}} src={`/images/currencies/${currency}.svg`} alt='' /> : currency }{' '}
                    </p>
                  )}
                </div>
              </Button>
            </Container>
          </div>
        </div>
      )}
      {renderPromoModal && (
        <ModalPromocode
          value={promoCode?.name ? promoCode.name : ''}
          onClose={closeModalPromo}
          active={activePromoModal}
          applyPromocode={val =>
            dispatch(
              setPromoCode(
                val.length
                  ? {
                    name: val,
                    discount: 0.1,
                  }
                  : undefined,
              ),
            )
          }
        />
      )}
    </Layout>
  );
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConfig, Toggles } from '../../utils/config/config.interface';
import { RootState } from '../store';
import { ITranslation, Language } from '../../utils/i18n/i18n.interface';


interface IConfigState {
  config: IConfig;
  translation: Record<Language, ITranslation>;
}

const initialState: IConfigState = {
  config: {
    toggles: {
      [Toggles.subscriptionEnabled]: false,
      [Toggles.cartEnabled]: false,
    },
  },
  translation: {
    [Language.en]: undefined,
    [Language.ru]: undefined,
  },
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<IConfig>) => {
      state.config = action.payload;
      window.localStorage.setItem('portBotUsername', action.payload.portBotUsername);
    },
    setTranslation: (
      state,
      action: PayloadAction<{ translation: ITranslation; language: Language }>,
    ) => {
      const { translation, language } = action.payload;
      state.config.language = language;
      state.translation[language] = translation;
    },
  },
});

export const { setConfig, setTranslation } = configSlice.actions;

export const configState = (state: RootState) => state[configSlice.name];

export const togglesState = (state: RootState) => state[configSlice.name].config.toggles;

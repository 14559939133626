import { Currency, DeliveryType } from '@teleport/schemas-protobuf';
import { hexRegex } from '../hexToRgb';
import { IConfig, IRemoteConfig, Toggles } from './config.interface';

export async function getConfig(): Promise<IConfig> {
  if (process.env.NODE_ENV === 'development') {
    // TODO Fix enums
    return {
      portApiUrl: 'https://italy-api.apps.teleportapp.store/',
      accentColor: '#eb4034',
      deliveryType: removeBackendPrefix('DELIVERY_TYPE_REQUEST', 'deliveryType'),
      language: removeBackendPrefix('LANGUAGE_RU', 'language'), // todo change to en
      currency: removeBackendPrefix('CURRENCY_RUB', 'currency'),
      cartEnabled: true,
      portBotUsername: 'TLPRT999A_Bot',
      storeLogo: 'https://upload.wikimedia.org/wikipedia/commons/8/87/Revolt-Logo-Black.jpg',
      storeName: 'PORT DEV',
      storeDescription: 'Алоха!',
      codeName: 'code',
      toggles: {
        [Toggles.subscriptionEnabled]: false,
        [Toggles.cartEnabled]: true,
      },
      cartMinimalPrice: 50
    };
  }

  try {
    const response = await fetch('/config.json');
    const config: IRemoteConfig = await response.json();

    return {
      portApiUrl: config.router['port-api'],
      deliveryType: removeBackendPrefix(config.deliveryType, 'deliveryType'),
      language: removeBackendPrefix(config.language, 'language'),
      currency: removeBackendPrefix(config.currency, 'currency'),
      accentColor: hexRegex.test(config.accentColor) ? config.accentColor : '#3390ec',
      portBotUsername: config.portBotUsername,
      cartEnabled: config.cartEnabled,
      storeLogo: config.storeLogo,
      storeName: config.storeName,
      storeDescription: config.storeDescription || '',
      codeName: config.codeName,
      toggles: config.toggles ?? {} as Record<Toggles, boolean>,
      cartMinimalPrice: config.cartMinimalPrice
    };
  } catch (error) {
    console.error('Could not load config', error);

    return null;
  }
}

// FIX ME
function removeBackendPrefix(string: any, configKey: 'deliveryType' | 'language' | 'currency') {
  if(!string) return undefined;

  switch (configKey) {
    case 'deliveryType':
      return Number(DeliveryType[string.replace('DELIVERY_TYPE_', '')]);
    case 'language':
      return string.replace('LANGUAGE_', '').toLowerCase();
    case 'currency':
      return Number(Currency[string.replace('CURRENCY_', '')]);
  }
}
